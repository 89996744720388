<template>
  <div class="mt-2 bg-white-900 mb-14">
    <div class="container mx-auto">
      <div v-if="isAuthenticated" class="flex mb-16 space-x-3">
        <new-catalogs class="flex-grow" :new-catalogs="newCatalogs" />
        <popular-publishers
          class="min-w-500 max-w-500"
          :publishers="popularPublishers"
        />
      </div>
      <actions-bar
        title="Find Books"
        title-class="text-4xl"
        placeholder="Search for books by keyword / title / author / ISBN"
        sortByType="books"
        :sortByValue="sortBy"
        :searchValue="searchValue"
        :hasSortByRelevance="true"
        @handleSortByChange="handleSortByChange"
        @handleSearchValueChange="searchValue = $event"
        @handleSearch="handleSearch"
        @handleSearchClear="() => {}"
      />
      <list-books
        :isBooksLoading="isBooksLoading"
        :books="books"
        :booksCount="booksCount"
        :filterParams="booksFilterParams"
        :filters="filters"
        @handleFilters="handleFilters"
        @handleFetchMore="handleFetchMore"
      />
      <recently-catalogs
        v-if="isAuthenticated && recentlyViewedCatalogs.length > 0"
        class="mt-13"
        :recentlyCatalogs="recentlyViewedCatalogs"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";

import NewCatalogs from "@/components/sliders/NewCatalogs.vue";
import RecentlyCatalogs from "@/components/sliders/RecentlyCatalogs.vue";
import PopularPublishers from "@/components/asides/PopularPublishers.vue";
import TabBooks from "@/components/tabs/Books.vue";
import {
  FETCH_SEARCH_INDEX,
  FETCH_BOOKS,
  FETCH_MORE_BOOKS,
  FETCH_BOOKS_FILTER_PARAMS,
} from "@/store/actions.type";
import { RELEVANCE_SORT_BY } from "@/common/constants";

export default {
  name: "Search",
  components: {
    "new-catalogs": NewCatalogs,
    "popular-publishers": PopularPublishers,
    "recently-catalogs": RecentlyCatalogs,
    "list-books": TabBooks,
    "actions-bar": () => import("@/components/base/ActionsBar.vue"),
  },
  async created() {
    if (!this.$route.query.q || this.$route.query.q.length === 0) {
      this.$router.push({ name: "home" });
    } else {
      await this.fetchBooks();
      this.books.forEach((book) => {
        if (
          book.isbn13 === this.$route.query.q ||
          book.isbn10 === this.$route.query.q
        ) {
          this.$router.push({
            name: "book",
            params: { handle: book.handle },
          });
        }
      });
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$store.dispatch(FETCH_SEARCH_INDEX);
      this.fetchFilterParams();
    }
  },
  data() {
    let filters = {
      publishers: [],
      imprints: [],
      subjects: [],
      publishingDate: [],
      authors: [],
      priceRange: [],
      available: "",
    };

    if (this.$route.query.fp) {
      filters.publishers = JSON.parse(decodeURIComponent(this.$route.query.fp));
    }

    if (this.$route.query.fi) {
      filters.imprints = JSON.parse(decodeURIComponent(this.$route.query.fi));
    }

    if (this.$route.query.fs) {
      filters.subjects = JSON.parse(decodeURIComponent(this.$route.query.fs));
    }

    if (this.$route.query.fpd) {
      filters.publishingDate = JSON.parse(
        decodeURIComponent(this.$route.query.fpd)
      );
    }

    if (this.$route.query.fa) {
      filters.authors = JSON.parse(decodeURIComponent(this.$route.query.fa));
    }

    if (this.$route.query.fpr) {
      filters.priceRange = JSON.parse(
        decodeURIComponent(this.$route.query.fpr)
      );
    }

    if (this.$route.query.fav) {
      filters.available = JSON.parse(decodeURIComponent(this.$route.query.fav));
    }

    return {
      searchValue: this.$route.query.q,
      sortBy: this.$route.query.s ? this.$route.query.s : RELEVANCE_SORT_BY,
      filters,
      page: 1,
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "newCatalogs",
      "popularPublishers",
      "recentlyViewedCatalogs",
      "isBooksLoading",
      "books",
      "booksCount",
      "booksFilterParams",
    ]),
  },
  methods: {
    getQueryParams() {
      return { q: this.searchValue, s: this.sortBy, f: this.filters };
    },
    updateQueryParams(isUpdateQuery) {
      let query = {
        q: isUpdateQuery ? this.searchValue : this.$route.query.q,
      };

      if (this.sortBy.length > 0) {
        query["s"] = this.sortBy;
      }

      if (this.filters.publishers.length > 0) {
        query["fp"] = encodeURIComponent(
          JSON.stringify(this.filters.publishers)
        );
      }

      if (this.filters.imprints.length > 0) {
        query["fi"] = encodeURIComponent(JSON.stringify(this.filters.imprints));
      }

      if (this.filters.subjects.length > 0) {
        query["fs"] = encodeURIComponent(JSON.stringify(this.filters.subjects));
      }

      if (this.filters.publishingDate.length > 0) {
        query["fpd"] = encodeURIComponent(
          JSON.stringify(this.filters.publishingDate)
        );
      }

      if (this.filters.authors.length > 0) {
        query["fa"] = encodeURIComponent(JSON.stringify(this.filters.authors));
      }

      if (this.filters.priceRange.length > 0) {
        query["fpr"] = encodeURIComponent(
          JSON.stringify(this.filters.priceRange)
        );
      }

      if (this.filters.available.length > 0) {
        query["fav"] = encodeURIComponent(
          JSON.stringify(this.filters.available)
        );
      }

      this.$router.push({
        name: "search",
        query,
      });
    },
    async fetchBooks() {
      await this.$store.dispatch(FETCH_BOOKS, this.getQueryParams());
    },
    fetchFilterParams() {
      this.$store.dispatch(FETCH_BOOKS_FILTER_PARAMS, { q: this.searchValue });
    },
    resetFilters() {
      this.filters = {
        publishers: [],
        imprints: [],
        subjects: [],
        publishingDate: [],
        authors: [],
        priceRange: [],
        available: "",
      };
    },
    handleSortByChange(event) {
      this.sortBy = event;
      this.updateQueryParams(false);
    },
    handleSearch() {
      if (this.searchValue) {
        this.resetFilters();
        this.sortBy = RELEVANCE_SORT_BY;
        this.updateQueryParams(true);
      }
    },
    handleFilters({ key, value }) {
      this.filters = {
        ...this.filters,
        [key]: value,
      };
      this.updateQueryParams(false);
    },
    handleFetchMore() {
      this.page++;
      this.$store.dispatch(FETCH_MORE_BOOKS, {
        ...this.getQueryParams(),
        p: this.page,
      });
    },
  },
};
</script>
