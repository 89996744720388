<template>
  <section
    class="
      bg-grey-400
      rounded
      px-8
      py-6
      min-w-0
      bg-no-repeat bg-right-bottom bg-book
    "
    :style="{ 'background-image': `url(${require('@/assets/book-bg.svg')})` }"
  >
    <div class="flex items-center justify-between mb-6">
      <h2 class="text-black-900 text-4xl font-medium">New Catalogs</h2>

      <router-link
        to="/catalogs"
        class="
          flex
          items-center
          font-medium
          text-grey-700 text-base
          group
          hover:text-black-900
        "
      >
        See all
        <svg
          class="ml-3"
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 9L5 5L1 1"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </router-link>
    </div>
    <div v-if="newCatalogs.length">
      <VueSlickCarousel
        v-bind="settings"
        ref="newCatalogsCarousel"
        class="mb-12"
        @beforeChange="changeCurrentSlide"
      >
        <div v-for="catalog in newCatalogs" :key="catalog.id">
          <router-link
            class="block w-141"
            :to="{ name: 'catalog', params: { handle: catalog.handle } }"
          >
            <img
              :src="catalog.imageUrl"
              :alt="catalog.name"
              class="shadow-md mb-3 h-auto"
              width="110"
            />
            <h3
              class="
                text-base text-black-900
                font-medium
                mb-2
                hover:text-orange-900
              "
            >
              {{ catalog.name }}
            </h3>
            <p
              class="
                text-sm text-black-600
                whitespace-nowrap
                overflow-hidden overflow-ellipsis
                mb-2
              "
            >
              {{ catalog.publisherName }}
            </p>

            <div
              class="
                flex
                items-center
                uppercase
                font-medium
                text-grey-700 text-sm
              "
            >
              <svg
                class="mr-1"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.996 3.32949L11.839 2.47131C11.8175 2.35356 11.715 2.26812 11.5955 2.26812C11.595 2.26812 11.5947 2.26812 11.5942 2.26812C11.4741 2.26862 11.372 2.35517 11.3514 2.47341L9.93904 10.6108L2.74085 10.611C2.56837 10.611 2.40877 10.5397 2.29101 10.4103C2.15605 10.2616 2.08089 10.0398 2.09897 9.84978C2.15172 9.39178 2.48281 9.0592 2.88571 9.0592H8.8929C9.01362 9.0592 9.11688 8.97215 9.13694 8.85292L10.5433 0.538748C10.5554 0.46681 10.5353 0.393262 10.4882 0.337543C10.4412 0.281825 10.372 0.249756 10.2992 0.249756H4.29675C2.92744 0.249756 1.5907 1.36338 1.31669 2.73133L0.0341729 9.01103C-0.081226 9.74627 0.0978156 10.4374 0.538362 10.9576C0.970984 11.4683 1.60531 11.7497 2.32457 11.7497L2.43922 11.7493C2.44145 11.7493 2.44356 11.7494 2.44603 11.7494C2.82913 11.7498 10.1556 11.7503 10.232 11.7503C10.5092 11.7503 10.7455 11.5517 10.7945 11.2727L11.9972 3.41122C12.0015 3.38422 12.0009 3.35636 11.996 3.32949Z"
                  fill="#A0A0A4"
                />
              </svg>

              {{ catalog.bookCount }}
              titles
            </div>
          </router-link>
        </div>
      </VueSlickCarousel>
      <div class="flex justify-between items-center">
        <slider-arrows
          @go-to-prev="showPrev"
          @go-to-next="showNext"
        ></slider-arrows>
        <slider-pagination
          :sliderLength="newCatalogs.length"
          :currentIndex="currentSlide"
          @go-to-slide="goToSlide"
        ></slider-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import SliderArrows from "./SliderArrows.vue";
import SliderPagination from "./SliderPagination.vue";

export default {
  name: "NewCatalogs",
  components: {
    VueSlickCarousel,
    SliderArrows,
    SliderPagination,
  },
  props: {
    newCatalogs: Array,
  },
  data() {
    return {
      settings: {
        arrows: false,
        slidesToShow: 5,
      },
      currentSlide: 0,
    };
  },
  methods: {
    showNext() {
      this.$refs.newCatalogsCarousel.next();
    },
    showPrev() {
      this.$refs.newCatalogsCarousel.prev();
    },
    goToSlide(i) {
      this.$refs.newCatalogsCarousel.goTo(i);
    },
    changeCurrentSlide(oldSlideIndex, newSlideIndex) {
      this.currentSlide = newSlideIndex;
    },
  },
};
</script>
