<template>
  <div>
    <h2 class="text-black-900 text-4xl font-medium mb-9">
      Recently Viewed Catalogs
    </h2>
    <VueSlickCarousel
      v-bind="settings"
      ref="recentlyCatalogsCarousel"
      class="mb-8"
    >
      <div v-for="catalog in recentlyCatalogs" :key="catalog.id" class="pr-3">
        <router-link class="block w-50" to="/">
          <img
            :src="catalog.imageUrl"
            :alt="catalog.name"
            class="shadow-md mb-3 h-auto"
            width="200"
          />
          <h3
            class="
              text-base text-black-900
              font-medium
              mb-2
              hover:text-orange-900
            "
          >
            {{ catalog.name }}
          </h3>
          <p class="text-sm text-grey-700 uppercase font-medium mb-2">
            {{ catalog.publisherName }}
          </p>

          <p class="text-sm text-black-600">{{ catalog.bookCount }} Titles</p>
        </router-link>
      </div>
    </VueSlickCarousel>
    <slider-arrows
      v-if="recentlyCatalogs.count > 6"
      @go-to-prev="showPrev"
      @go-to-next="showNext"
    ></slider-arrows>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import SliderArrows from "./SliderArrows.vue";

export default {
  name: "RecentlyCatalogs",
  components: {
    VueSlickCarousel,
    SliderArrows,
  },
  props: {
    recentlyCatalogs: Array,
  },
  data() {
    return {
      settings: {
        arrows: false,
        slidesToShow: 6,
      },
    };
  },
  methods: {
    showNext() {
      this.$refs.recentlyCatalogsCarousel.next();
    },
    showPrev() {
      this.$refs.recentlyCatalogsCarousel.prev();
    },
  },
};
</script>
