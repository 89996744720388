<template>
  <div class="flex">
    <button class="text-black-900 mr-5 hover:text-orange-900" @click="goToPrev">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M14 5L7 12L14 19"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
    <button class="text-black-900 hover:text-orange-900" @click="goToNext">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M10 5L17 12L10 19"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "SliderArrows",
  methods: {
    goToPrev() {
      this.$emit("go-to-prev");
    },
    goToNext() {
      this.$emit("go-to-next");
    },
  },
};
</script>
