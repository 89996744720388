<template>
  <section class="bg-grey-400 rounded px-8 py-6">
    <div class="flex items-center justify-between mb-6">
      <h2 class="text-black-900 text-4xl font-medium">Popular Publishers</h2>

      <router-link
        to="/publishers"
        class="
          flex
          items-center
          text-grey-700 text-base
          font-medium
          group
          hover:text-black-900
        "
      >
        See all
        <svg
          class="ml-3"
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 9L5 5L1 1"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </router-link>
    </div>

    <ul class="space-y-3">
      <li
        v-for="publisher of publishers"
        :key="publisher.id"
        class="flex items-start"
      >
        <router-link
          class="
            rounded
            bg-white-900
            shadow-xs
            py-2
            px-1
            mr-5
            flex-shrink-0
            w-14
            h-14
          "
          :to="{ name: 'publisher', params: { handle: publisher.handle } }"
        >
          <img
            :src="publisher.imageUrl"
            :alt="publisher.name"
            class="w-full h-full object-contain"
          />
        </router-link>

        <div>
          <router-link
            :to="{ name: 'publisher', params: { handle: publisher.handle } }"
            class="
              text-base text-black-900
              font-medium
              mb-2
              hover:text-orange-900
              block
            "
          >
            {{ publisher.name }}
          </router-link>

          <ul class="flex items-center space-x-4">
            <li
              class="
                flex
                items-center
                font-medium
                uppercase
                text-grey-700 text-sm
              "
            >
              <svg
                class="mr-1"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 -3.27835e-08C11.6642 -1.46777e-08 12 0.335786 12 0.75L12 6.75C12 7.16421 11.6642 7.5 11.25 7.5L5.25 7.5C4.83579 7.5 4.5 7.16421 4.5 6.75L4.5 0.75C4.5 0.335786 4.83579 -3.13158e-07 5.25 -2.95052e-07L11.25 -3.27835e-08Z"
                  fill="#A0A0A4"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.25 9C10.25 9.41421 9.91421 9.75 9.5 9.75L3 9.75C2.58579 9.75 2.25 9.41421 2.25 9L2.25 2.5C2.25 2.08579 2.58579 1.75 3 1.75C3.41421 1.75 3.75 2.08579 3.75 2.5L3.75 8.25L9.5 8.25C9.91421 8.25 10.25 8.58579 10.25 9Z"
                  fill="#A0A0A4"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 11.25C8 11.6642 7.66421 12 7.25 12L0.75 12C0.335786 12 -3.35013e-07 11.6642 -3.16908e-07 11.25L-3.27835e-08 4.75C-1.46777e-08 4.33579 0.335786 4 0.75 4C1.16421 4 1.5 4.33579 1.5 4.75L1.5 10.5L7.25 10.5C7.66421 10.5 8 10.8358 8 11.25Z"
                  fill="#A0A0A4"
                />
              </svg>

              {{ publisher.catalogCount }}
              catalogs
            </li>
            <li
              class="
                flex
                items-center
                font-medium
                uppercase
                text-grey-700 text-sm
                before:empty-content
                before:bg-black-600
                before:w-0.5
                before:h-0.5
                before:absolute
                before:top-1/2
                before:-left-2
                before:transform
                before:-translate-y-1/2
                relative
              "
            >
              <svg
                class="mr-1"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.996 3.32949L11.839 2.47131C11.8175 2.35356 11.715 2.26812 11.5955 2.26812C11.595 2.26812 11.5947 2.26812 11.5942 2.26812C11.4741 2.26862 11.372 2.35517 11.3514 2.47341L9.93904 10.6108L2.74085 10.611C2.56837 10.611 2.40877 10.5397 2.29101 10.4103C2.15605 10.2616 2.08089 10.0398 2.09897 9.84978C2.15172 9.39178 2.48281 9.0592 2.88571 9.0592H8.8929C9.01362 9.0592 9.11688 8.97215 9.13694 8.85292L10.5433 0.538748C10.5554 0.46681 10.5353 0.393262 10.4882 0.337543C10.4412 0.281825 10.372 0.249756 10.2992 0.249756H4.29675C2.92744 0.249756 1.5907 1.36338 1.31669 2.73133L0.0341729 9.01103C-0.081226 9.74627 0.0978156 10.4374 0.538362 10.9576C0.970984 11.4683 1.60531 11.7497 2.32457 11.7497L2.43922 11.7493C2.44145 11.7493 2.44356 11.7494 2.44603 11.7494C2.82913 11.7498 10.1556 11.7503 10.232 11.7503C10.5092 11.7503 10.7455 11.5517 10.7945 11.2727L11.9972 3.41122C12.0015 3.38422 12.0009 3.35636 11.996 3.32949Z"
                  fill="#A0A0A4"
                />
              </svg>

              {{ publisher.bookCount }}
              books
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    publishers: Array,
  },
};
</script>
